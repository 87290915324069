import setFocus from '../helpers/set-focus';
import scrollTo from '../helpers/scroll-to';
import onResize from '../helpers/on-resize';
import onScroll from '../helpers/on-scroll';
import transitionEnd from '../helpers/transition-end';
import { viewport, easings } from '../helpers/variables';
import { $, $$ } from '../helpers/query-selector';

const header = $('.js-header');
const headerWrapper = $('.js-header-wrapper');
const footer = $('.js-footer');
const main = $('.js-main');
const nav = $('.js-nav');
const navWrapper = $('.js-nav-mobile');
const navToggle = $('.js-nav-toggle');
const navIcon = $('.js-nav-icon');
const menuButton = $('.js-quicknav-menu-button');
const contactOpenButton = $('.js-quicknav-contact-open-button');
const contactCloseButton = $('.js-quicknav-contact-close-button');
const headerHideThreshold = 100;
const headerHiddenClass = 'l-header--hidden';
const headerShadowClass = 'l-header--shadow';

let navVisible = false;
let contactVisible = false;
let scrollPosition = 0;
let fixedHeader = false;
let headerOnlyScroll = false;
const quickNavThreshold = 200;
const headerThreshold = 70;

// make header + nav only elements in flow
function addHeaderOnlyScroll() {
  if (headerOnlyScroll) return;
  headerOnlyScroll = true;

  const bodyTop = document.body.getBoundingClientRect().top;
  const mainTop = main.getBoundingClientRect().top;
  const footerTop = footer.getBoundingClientRect().top;

  main.style.setProperty('position', 'fixed');
  footer.style.setProperty('position', 'fixed');
  main.style.setProperty('opacity', 0);
  footer.style.setProperty('opacity', 0);
  main.style.setProperty('width', '100%');
  footer.style.setProperty('width', '100%');
  main.style.setProperty('top', mainTop - bodyTop - scrollPosition + 'px');
  footer.style.setProperty('top', footerTop - bodyTop - scrollPosition + 'px');
  header.classList.remove('l-header--fixed');
  window.scrollTo(0, 0); // fix jumps
}

function removeHeaderOnlyScroll() {
  if (!headerOnlyScroll) return;
  headerOnlyScroll = false;

  main.style.removeProperty('position');
  footer.style.removeProperty('position');
  main.style.removeProperty('width');
  footer.style.removeProperty('width');
  main.style.removeProperty('opacity');
  footer.style.removeProperty('opacity');
  main.style.removeProperty('top');
  footer.style.removeProperty('top');
  header.classList.add('l-header--fixed');
  window.scrollTo(0, scrollPosition);
}

async function showNav() {
  navVisible = true;
  fixedHeader = true;
  if (!contactVisible) scrollPosition = window.pageYOffset; // if contactVisible, use older scroll position

  navToggle.setAttribute('aria-expanded', 'true');
  navIcon.classList.add('c-nav-icon--active');

  navWrapper.classList.add('l-nav__wrapper--active');
  navWrapper.classList.add('l-nav__wrapper--anim');

  await transitionEnd(navWrapper, 'opacity');

  if (contactVisible) header.classList.add('l-header--contact-and-nav-visible');
  addHeaderOnlyScroll();
}

async function hideNav() {
  navVisible = false;
  if (!contactVisible) removeHeaderOnlyScroll();
  else header.classList.remove('l-header--contact-and-nav-visible');

  navToggle.setAttribute('aria-expanded', 'false');
  navIcon.classList.remove('c-nav-icon--active');

  navWrapper.classList.remove('l-nav__wrapper--active');
  await transitionEnd(navWrapper, 'opacity');
  navWrapper.classList.remove('l-nav__wrapper--anim');

  header.classList.remove(headerHiddenClass);
  header.classList.add(headerShadowClass);
}

function initNav() {
  if (!navToggle) return;

  navToggle.addEventListener('click', e => {
    navVisible ? hideNav() : showNav();
  });

  // Close nav if link is clicked
  navWrapper.addEventListener('click', e => {
    if (navVisible && e.target.tagName === 'A') hideNav();
  });
}

function showFixedHeader() {
  fixedHeader = true;
  header.classList.add('l-header--fixed');

  headerWrapper.style.setProperty('transform', 'translateY(-2rem)');
  headerWrapper.style.setProperty('opacity', '0');
  const forceLayout = headerWrapper.offsetWidth;
  headerWrapper.style.setProperty(
    'transition',
    `transform 400ms ${easings.out}, opacity 200ms ${easings.fade}`
  );
  headerWrapper.style.setProperty('transform', 'translateY(0%)');
  headerWrapper.style.setProperty('opacity', '1');
}

async function hideFixedHeader(animated = true) {
  fixedHeader = false;

  if (!animated) {
    header.classList.remove('l-header--fixed');
    headerWrapper.removeAttribute('style');
  } else {
    headerWrapper.style.setProperty(
      'transition',
      `transform 200ms ${easings.out}, opacity 100ms ${easings.fade}, visibility 0ms linear 100ms`
    );
    headerWrapper.style.setProperty('transform', 'translateY(-2rem)');
    headerWrapper.style.setProperty('opacity', '0');

    await transitionEnd(headerWrapper, 'opacity');

    header.classList.remove('l-header--fixed');
    headerWrapper.removeAttribute('style');
  }
}

function showQuickNav() {
  header.classList.add('l-header--quicknav-visible');
}

function hideQuickNav() {
  header.classList.remove('l-header--quicknav-visible');
}

function showContact() {
  contactVisible = true;
  header.classList.add('l-header--contact-visible');

  addHeaderOnlyScroll();

  setFocus(contactCloseButton); // not sure if actually an a11y improvement

  import('./map').then(module => module.default(true));
}

function hideContact() {
  contactVisible = false;
  header.classList.remove('l-header--contact-visible');
  removeHeaderOnlyScroll();
}

function overlayPossible() {
  const hideQuickNavArea = $('.js-hide-quicknav-area');
  if (!hideQuickNavArea) return true;

  let offset = calcOffset();

  // better: use IntersectionObserver
  function calcOffset() {
    const bodyRect = document.body.getBoundingClientRect();
    const elRect = hideQuickNavArea.getBoundingClientRect();

    let top = elRect.top - bodyRect.top;
    const bottom = top + elRect.height;

    top -= 50; // ~ height of quicknav

    return { top, bottom };
  }

  onResize(() => {
    offset = calcOffset();
  });

  let noOverlay = true;

  if (window.pageYOffset > offset.top && window.pageYOffset < offset.bottom) {
    noOverlay = false;
  } else {
    noOverlay = true;
  }

  return noOverlay;
}

function openSubNav(item) {
  const button = $('.js-sub-nav-button', item);
  const list = $('.js-sub-nav-list', item);

  button.setAttribute('aria-expanded', true);
  list.classList.add('visible');

  // Get x position relative to header
  const headerRect = header.getBoundingClientRect();
  const buttonRect = button.getBoundingClientRect();
  const xOffset = headerRect.right - buttonRect.left;

  // Set x offset as CSS variable to position subnav items relative to button
  list.style.setProperty('--subnav-x-offset', `${Math.round(xOffset)}px`);
}

function closeSubNav(item) {
  const button = $('.js-sub-nav-button', item);
  const list = $('.js-sub-nav-list', item);

  button.setAttribute('aria-expanded', false);
  list.classList.remove('visible');

  const infos = $$('.js-sub-nav-info', item);

  for (const info of infos) {
    info.classList.remove('visible');
  }
}

function toggleSubNav(item) {
  const button = $('.js-sub-nav-button', item);

  if (button.getAttribute('aria-expanded') == 'true') {
    closeSubNav(item);
  } else {
    openSubNav(item);
  }
}

function closeAllSubnavs() {
  const items = $$('.js-sub-nav');

  for (const item of items) {
    closeSubNav(item);
  }
}

function initSubNav() {
  const items = $$('.js-sub-nav');

  for (const item of items) {
    const button = $('.js-sub-nav-button', item);

    button.addEventListener('click', () => {
      toggleSubNav(item);
    });
    item.addEventListener('mouseenter', () => {
      openSubNav(item);
    });
    item.addEventListener('mouseleave', () => {
      closeSubNav(item);
    });

    // Subnav info
    const subItems = $$('.js-sub-nav-item', item);

    for (const subItem of subItems) {
      subItem.addEventListener('mouseenter', () => {
        const info = $('[data-id="' + subItem.id + '"]', item);
        if (info) info.classList.add('visible');
      });
      subItem.addEventListener('mouseleave', () => {
        const info = $('[data-id="' + subItem.id + '"]', item);
        if (info) info.classList.remove('visible');
      });
    }
  }
}

export default () => {
  if (!nav) return;

  initNav();
  initSubNav();

  let prevScrollY = 0;

  onScroll(() => {
    if (contactVisible || navVisible) return;

    if (window.scrollY < 5) {
      header.classList.remove(headerShadowClass);
    } else {
      header.classList.add(headerShadowClass);
    }

    // Scroll up
    if (window.scrollY < prevScrollY) {
      if (window.scrollY > headerHideThreshold) {
        header.classList.remove(headerHiddenClass);
        header.classList.add(headerShadowClass);
      }
    }
    // Scroll down
    else {
      if (window.scrollY > headerHideThreshold) {
        header.classList.add(headerHiddenClass);
        header.classList.remove(headerShadowClass);
        closeAllSubnavs();
      }
    }

    prevScrollY = window.scrollY;
  });

  onResize(
    () => {
      hideContact();
      hideNav();
    },
    { horizontalOnly: true }
  );
};
